.appt-book {
  #appointment-book-sections {
    .appointment-booking-review__account-signup-form {
      .signup__terms,
      .signup__email-list,
      .signup__sms-list {
        width: 100%;
      }
    }
    .confirmation {
      .confirmation-container {
        .appt-service {
          .right {
            float: none;
            .copy {
              float: right;
            }
          }
        }
      }
    }
    .pika-prev {
      float: right;
    }
    .pika-next {
      float: left;
    }
  }
}

.appointment-booking-schedule__overlay {
  .overlay-content-container {
    right: 5%;
  }
}

#account-my-appointments {
  .appt-book-no-appts-content-bgimg.pc-hidden {
    background-position: right !important;
  }
  #my-appointments-past {
    #past-appt-body {
      .appointment-details {
        .name {
          width: 100% !important;
        }
      }
    }
  }
}

.appointment-booking-review__account-signup-form {
  .signup__mobile-prefix {
    width: 55px !important;
  }
  .signup__mobile-phone {
    padding-left: 7px !important;
  }
}

.loyalty-page {
  @include clearfix;
  &__errors {
    li {
      margin-bottom: 14px;
    }
  }
  &__content {
    width: auto;
    overflow: hidden;
  }
  &__title_msg {
    @include breakpoint($medium-up) {
      border-bottom: 2px solid $color-black;
      margin: 4px 0 17px 0;
      padding-bottom: 16px;
    }
    h1 {
      margin-bottom: 10px;
    }
  }
  &__header {
    @include breakpoint($small-down) {
      @include h13;
    }
    @include breakpoint($medium-up) {
      @include h19;
      text-indent: $account-indent;
    }
  }
}

.loyalty-info {
  font-size: 20px;
  line-height: 25px;
  padding: 0 15px;
  text-align: center;
  span {
    font-weight: bold;
  }
  a {
    display: block;
    text-decoration: none;
    &.first {
      margin-bottom: 25px;
    }
  }
}

// RTL support
@mixin swap_direction($type, $v) {
  @if length($v) == 4 {
    @if $text-direction == rtl {
      #{$type}: nth($v, 1) nth($v, 4) nth($v, 3) nth($v, 2);
    } @else {
      #{$type}: $v;
    }
  } @else {
    #{$type}: $v;
  }
}

// Columns
[dir='rtl'] .checkout__content {
  @include breakpoint($medium-up) {
    float: right;
  }
}

[dir='rtl'] .checkout__sidebar {
  @include breakpoint($medium-up) {
    float: right;
  }
}

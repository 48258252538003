// Importing the font

// Assistant Regular
@font-face {
  font-family: 'Assistant Regular';
  src: url('#{$netstorage-fonts-path}arabic/Assistant/Assistant-Regular.woff2') format('woff2');
  font-display: swap;
  unicode-range: $unicode-range-hebrew, $unicode-currency-shekel, $unicode-range-numerals-symbols;
}

// Assistant ExtraLight
@font-face {
  font-family: 'Assistant ExtraLight';
  src: url('#{$netstorage-fonts-path}arabic/Assistant/Assistant-ExtraLight.woff2') format('woff2');
  font-display: swap;
  unicode-range: $unicode-range-hebrew, $unicode-currency-shekel, $unicode-range-numerals-symbols;
}

// Assistant Light
@font-face {
  font-family: 'Assistant Light';
  src: url('#{$netstorage-fonts-path}arabic/Assistant/Assistant-Light.woff2') format('woff2');
  font-display: swap;
  unicode-range: $unicode-range-hebrew, $unicode-currency-shekel, $unicode-range-numerals-symbols;
}

// Assistant Medium
@font-face {
  font-family: 'Assistant Medium';
  src: url('#{$netstorage-fonts-path}arabic/Assistant/Assistant-Medium.woff2') format('woff2');
  font-display: swap;
  unicode-range: $unicode-range-hebrew, $unicode-currency-shekel, $unicode-range-numerals-symbols;
}

// Assistant SemiBold
@font-face {
  font-family: 'Assistant SemiBold';
  src: url('#{$netstorage-fonts-path}arabic/Assistant/Assistant-SemiBold.woff2') format('woff2');
  font-display: swap;
  unicode-range: $unicode-range-hebrew, $unicode-currency-shekel, $unicode-range-numerals-symbols;
}

// Assistant Bold
@font-face {
  font-family: 'Assistant Bold';
  src: url('#{$netstorage-fonts-path}arabic/Assistant/Assistant-Bold.woff2') format('woff2');
  font-display: swap;
  unicode-range: $unicode-range-hebrew, $unicode-currency-shekel, $unicode-range-numerals-symbols;
}

// Assistant ExtraBold
@font-face {
  font-family: 'Assistant ExtraBold';
  src: url('#{$netstorage-fonts-path}arabic/Assistant/Assistant-ExtraBold.woff2') format('woff2');
  font-display: swap;
  unicode-range: $unicode-range-hebrew, $unicode-currency-shekel, $unicode-range-numerals-symbols;
}

@font-face {
  font-family: $spoiler;
  src: url('#{$netstorage-fonts-path}Spoiler/Spoiler.woff2') format('woff2');
  font-display: swap;
  unicode-range: $unicode-range-hebrew, $unicode-currency-shekel, $unicode-range-numerals-symbols;
}

@font-face {
  font-family: $spoiler-light;
  src: url('#{$netstorage-fonts-path}Spoiler/Spoiler-Light.woff2') format('woff2');
  font-display: swap;
  unicode-range: $unicode-range-hebrew, $unicode-currency-shekel, $unicode-range-numerals-symbols;
}

@font-face {
  font-family: $spoiler-regular;
  src: url('#{$netstorage-fonts-path}Spoiler/Spoiler-Regular.woff2') format('woff2');
  font-display: swap;
  unicode-range: $unicode-range-hebrew, $unicode-currency-shekel, $unicode-range-numerals-symbols;
}

@font-face {
  font-family: $spoiler-bold;
  src: url('#{$netstorage-fonts-path}Spoiler/Spoiler-Bold.woff2') format('woff2');
  font-display: swap;
  unicode-range: $unicode-range-hebrew, $unicode-currency-shekel, $unicode-range-numerals-symbols;
}

@font-face {
  font-family: $spoiler-black;
  src: url('#{$netstorage-fonts-path}Spoiler/Spoiler-Black.woff2') format('woff2');
  font-display: swap;
  unicode-range: $unicode-range-hebrew, $unicode-currency-shekel, $unicode-range-numerals-symbols;
}

// Brandon Text Font Weight Bold
@font-face {
  font-family: 'Brandon Text';
  src: url('#{$netstorage-fonts-path}Brandon/brandontext_bold-webfont.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: normal;
  unicode-range: $unicode-range-latin;
}
// Brandon Text Bold Font Weight Bold
@font-face {
  font-family: 'Brandon Text Bold';
  src: url('#{$netstorage-fonts-path}Brandon/brandontext_bold-webfont.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: bold;
  unicode-range: $unicode-range-latin;
}

@mixin button-link {
  font-family: $bbtext-bold;
}

// Locale overrides
.product-grid,
.product-grid--quickshop {
  .shade-picker__color-name a,
  .shade-picker__color-name span {
    font-family: $brandon-text-medium;
  }
  .product-thumb__headline a,
  .product_quickshop__header {
    font-family: $bbtext-bold;
  }
}

a {
  &.button {
    @include button-link;
  }
}

input.form-submit,
input.button[type='submit'] {
  @include button-link;
}

.selectBox-options a {
  font-family: $brandon-text-medium;
}

h2,
.h2 {
  font-size: 36px;
}

html,
body,
div,
ul,
li,
a,
p,
span,
table,
tr,
th,
td,
input,
form,
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
dl,
dt,
dd,
label,
small,
select,
article,
textarea,
.button--underline {
  font-family: $bbtext-regular;
}

strong,
b {
  font-family: $bbtext-bold;
}

.product__price span,
h3.product_brief__header,
.customer-service-section h2,
.button--inverted,
.responsive-tabs .resp-tab-item {
  font-family: $bbtext-bold;
}

.gnav-section__link {
  font-size: 18px !important;
}

.product-brief__sub-header,
.product-brief__short-desc,
.product-brief__short-desc-link {
  font-size: 15px !important;
}

.product-grid,
.product-grid--quickshop {
  .shade-picker__color-name a,
  .shade-picker__color-name span {
    font-family: $bbtext-regular;
  }
  span.price,
  h3,
  h3 a,
  .desktop-text,
  .mobile-text {
    font-family: $bbtext-bold;
  }
}

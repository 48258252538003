.section-waitlist {
  .waitlist {
    .submit-button {
      input {
        float: none;
      }
    }
    &__icon-down {
      transform: rotate(225deg);
    }
    &__icon-up {
      transform: rotate(45deg);
    }
    .success {
      .button {
        margin: 20px;
      }
    }
  }
}

@if $cr22 == true {
  // Setting the translateX from a negative value to a positive value for the CR22 gnav overlay when on a RTL locale
  .site-header-formatter-overlay {
    input[type='radio'] ~ &,
    .header-nav-section__trigger:checked + .header-nav-section__section + & {
      transform: translateX(50%);
    }
  }
  // Setting the arrow position to fit for the CR22 redesign on a RTL locale
  .mpp-container {
    .mpp-container__controls {
      .mpp-sort-menu.selectBox {
        padding-#{$rdirection}: 15px;
        .selectBox-arrow {
          width: 10px;
          #{$rdirection}: 0;
        }
      }
    }
  }
  // Setting the arrow position for the offers banner on a RTL locale
  .site-header-formatter__offers {
    .header-offers-banner-formatter {
      &__arrow-prev {
        #{$ldirection}: auto;
        #{$rdirection}: 20px;
        @include breakpoint($large-up) {
          #{$rdirection}: 55px;
        }
        .icon--dropdown {
          #{$ldirection}: auto;
          #{$rdirection}: 0;
        }
      }
      &__arrow-next {
        #{$ldirection}: 20px;
        #{$rdirection}: auto;
        .icon--dropdown {
          #{$ldirection}: 0;
          #{$rdirection}: auto;
        }
      }
    }
  }
  .carousel-controls,
  .product-brief-shades {
    button {
      &.slick-next {
        height: auto;
        #{$ldirection}: auto;
        transform: rotate(180deg);
        &:before {
          position: relative;
          #{$ldirection}: 0;
          transform: none;
        }
      }
      &.slick-prev {
        height: auto;
        #{$rdirection}: auto;
        transform: rotate(180deg);
        &:before {
          transform: none;
        }
      }
    }
  }
}

// Setting the arrow direction for the footer submit button on a RTL locale
.sitewide-footer-formatter {
  .site-footer__email-signup {
    .site-footer-email-signup__submit label {
      transform: scaleX(-1);
    }
  }
}

// Flipping the "slash" over a shade dot on MPP for sold out shades when on a RTL locale
.product-brief-shades {
  .product-brief-shades__grid {
    .shade-inventory-status--4,
    .shade-inventory-status--7 {
      &:after {
        transform: rotate(-45deg);
      }
    }
  }
}

.site-email-sms-signup {
  &__birthday {
    text-align: $ldirection;
  }
}

.account-mobile-landing-page {
  .account-landing-profile {
    h2.account-landing-profile__header span {
      background: none repeat scroll 0 0 #ffffff;
      display: inline-block;
      @include swap_direction(padding, 0 7px 0 9px);
      position: relative;
    }
  }
}

.account__section {
  @include swap_direction(margin, 40px auto);
  max-width: 1024px;
  overflow: hidden;
}

.new-account {
  input[type='text'],
  input[type='email'] {
    margin-top: 10px;
    width: 100%;
  }
}

.profile-page {
  .profile-page__content {
    #profile_preferences {
      .newsletter-info {
        .newsletter-info__fieldset {
          .newsletter-info__text {
            display: none;
          }
        }
      }
      .optional-info label {
        display: block;
      }
      .optional-info__item {
        width: 30%;
      }
      .profile-info {
        .profile-info__text {
          @include swap_direction(padding, 20px);
        }
      }
    }
    .profile-page__head {
      .profile-page__link {
        @include swap_direction(padding, 10px);
      }
    }
  }
}

.address-book-page {
  .address-book-page__content {
    h1.address-book-page__header span {
      background: none repeat scroll 0 0 #ffffff;
      display: inline-block;
      @include swap_direction(padding, 0 7px 0 9px);
      position: relative;
    }
  }
}

.address-item__controls a {
  line-height: 25px;
  border-bottom: 1px solid #ff4661;
  text-transform: uppercase;
  text-decoration: none;
  font-family: 'Brandon Text Bold';
  font-size: 11px;
}

.shipping-billing {
  margin-top: 40px;
  @include swap_direction(padding, 0px 20px 20px 20px);
  .address-form h1.address-form__header {
    @include swap_direction(margin, 10px 0px 10px 0px);
    text-align: center;
    font-size: 14px;
    font-weight: bold;
  }
}

.upload-file__imposter {
  background-color: #ff4661;
  color: #fff;
  width: 140px;
  font-size: 14px;
  text-transform: uppercase;
  @include swap_direction(padding, 10px);
  font-weight: bold;
}

.upload-file__value {
  text-transform: uppercase;
  border-bottom: 1px solid;
  font-weight: bold;
  font-size: 14px;
}

.profile-page .profile-page__content {
  #profile_preferences .optional-info__fieldset {
    label {
      display: block;
    }
    .optional-info__item {
      @include swap_direction(margin, 0 2% 5px 0);
      width: 30%;
      .field {
        min-width: 110%;
      }
    }
  }
}

.password-sent-page {
  .sent-info__text {
    @include swap_direction(margin, 10px 0px);
  }
}

.password-reset-page {
  .password-reset__item {
    @include swap_direction(margin, 10px);
    input {
      width: 100%;
    }
  }
  #password_reset {
    .password-reset__submit {
      @include swap_direction(margin, 10px);
    }
  }
}

.account__section {
  @include swap_direction(margin, 0px);
  @include swap_direction(padding, 20px);
}

.favorites-page .favorites-page__content {
  @include swap_direction(padding, 15px);
  .favorites__recommended-products-slide {
    padding: 0;
  }
  .slick-slider {
    .slick-prev {
      right: -15px;
    }
  }
  h2.favorites__instruction-step-title {
    line-height: 35px;
  }
}

.shipping-billing .address-form {
  #address .address-form__fieldset {
    #form--address--field--TITLE {
      width: 100%;
    }
  }
}

.order-details {
  .order-totals {
    table {
      th {
        width: 60%;
        text-align: $rdirection;
        word-break: break-word;
      }
      td {
        width: 40%;
        text-align: $rdirection;
        padding-#{$ldirection}: 5px;
      }
    }
  }
}

.past-purchases-page {
  .past-purchases-page__header {
    span {
      background: #ffffff;
      display: inline-block;
      position: relative;
    }
  }
  .past-purchases {
    .product-item {
      padding-top: 15px;
      margin-bottom: 15px;
      border-top: 1px #bbbbb9 solid;
      .product-item__img img {
        @include swap_direction(margin, 0px auto);
        display: block;
      }
      .product-item__details {
        div,
        h4 {
          text-align: center;
        }
      }
      .product-item__list li {
        text-align: center;
        .product-item__swatch {
          display: inline-block;
        }
        .product-item__shade {
          display: inline-block;
          vertical-align: top;
          line-height: 21px;
        }
      }
      .product-item__name a {
        font-family: 'Brandon Text Bold';
        font-weight: normal;
        font-size: 11px;
        line-height: 19px;
        letter-spacing: 0.22em;
        text-transform: uppercase;
        color: black;
        text-decoration: none;
      }
      .product-item__add-to-cart {
        margin-top: 20px;
      }
    }
    .product-item:first-child {
      border: none;
    }
  }
}

#favorites {
  .confirm-delete {
    &.js-confirm-box {
      .confirm-delete__link {
        @include swap_direction(padding, 15px);
      }
    }
  }
}

.touch {
  .my-lists__list-link--add-all {
    width: 91%;
    text-align: center;
  }
}

.address-form {
  &.address-book-page {
    .address-form__item {
      &.city_container {
        input {
          width: 100%;
        }
      }
    }
  }
}

#pr-write,
#pr-reviewdisplay,
.product-grid__content,
.product-full__details,
.product-full__review-snippet,
#pr-reviewsnippet,
.search-wrapper__results,
#pr-questiondisplay {
  .p-w-r {
    .pr {
      &-review-display {
        .pr-review {
          .pr-rd-review-headline {
            text-align: $ldirection;
            margin-#{$ldirection}: 0;
            width: 100%;
          }
          .pr-rd-side-content-block {
            &.pr-rd-right {
              position: relative;
              top: 0;
              #{$ldirection}: 0;
              text-align: $ldirection;
              @include breakpoint($medium-up) {
                position: absolute;
                #{$rdirection}: -40%;
                #{$ldirection}: auto;
              }
            }
          }
          .pr-rd-content-block {
            &.pr-accordion {
              text-align: $ldirection;
            }
          }
          .pr-accordion-btn {
            padding-#{$ldirection}: 0;
          }
        }
        .pr-rd-description-text {
          text-align: $ldirection;
        }
        .pr-rd-footer {
          .pr-rd-bottomline {
            text-align: $ldirection;
          }
          .pr-rd-helpful-action {
            width: 100%;
          }
        }
        .pr-helpful {
          &-yes {
            .pr-helpful-count {
              &:before {
                content: 'כן';
              }
            }
          }
          &-no {
            .pr-helpful-count {
              &:before {
                content: 'לא';
              }
            }
          }
        }
        .pr-rd-helpful-action-legend {
          text-align: $ldirection;
        }
        .pr-rd-flag-review-container {
          margin: 15px 0;
          text-align: $ldirection;
        }
      }
    }
    #pr-rating {
      float: $ldirection;
    }
    .pr-rating-stars {
      float: $ldirection;
      padding-bottom: 0px;
      margin-top: 3px;
      span {
        float: $ldirection;
      }
    }
    #pr-review-snapshot {
      .pr-ratings-histogram-bar-container {
        width: 100%;
      }
      .pr-review-snapshot-block-container {
        .pr-review-snapshot-block-histogram {
          @include breakpoint($portrait-up) {
            width: 64.66%;
            float: $ldirection;
          }
        }
        .pr-review-snapshot-block-headline {
          text-align: $ldirection;
        }
        .pr-snapshot-tag-def {
          text-align: $ldirection;
          float: $ldirection;
          @include breakpoint($portrait-up) {
            float: none;
          }
        }
      }
    }
    .pr-snippet-stars-reco-inline {
      .pr-snippet-read-and-write {
        width: 200px;
        @include breakpoint($portrait-up) {
          width: 120px;
        }
        @include breakpoint($small-down) {
          position: inherit;
        }
      }
    }
    .pr-flag-review {
      .pr-flagging-radiogroup,
      .pr-flag-review-label {
        text-align: $ldirection;
      }
      .pr-flagging-radio {
        margin-#{$ldirection}: 0;
        .pr-form-radio {
          position: relative;
          #{$ldirection}: 0;
        }
        span {
          margin-#{$ldirection}: 20px;
        }
      }
      .pr-form-control-error-icon {
        #{$ldirection}: auto;
        #{$rdirection}: 5px;
      }
      .pr-flag-review-btn:hover {
        background-color: $color-black !important;
      }
      .pr-flag-review-btn-submit:hover {
        background-color: $color-pink !important;
      }
    }
  }
  .thank-you-page {
    h1.title,
    h3.subtitle {
      text-align: $ldirection;
    }
    .col-sm-12 {
      text-align: $ldirection;
      .headline {
        text-align: $ldirection;
      }
      .pr-rating-number {
        float: $ldirection;
        span {
          float: none;
          display: inline;
        }
      }
    }
    .pr-rating-date {
      float: $rdirection !important;
    }
  }
}

.pr-star-v4 {
  transform: scaleX(-1);
}

.product__rating {
  .product-full__review-snippet {
    .p-w-r {
      .pr-snippet-stars-reco-inline.pr-snippet-minimal {
        .pr-snippet {
          padding-#{$ldirection}: 0;
          .pr-snippet-stars-container {
            float: $ldirection;
          }
          .pr-snippet-read-and-write {
            display: block;
          }
        }
      }
    }
  }
}

#power_review_container {
  .product-full {
    &__details {
      @include breakpoint($small-down) {
        width: 100%;
      }
    }
  }
  #pr-war-form {
    .form-group {
      .pr-form-control-error-wrapper {
        float: $ldirection;
        width: 100%;
      }
      .pr-form-control-error-icon {
        #{$rdirection}: 5px;
        #{$ldirection}: auto;
      }
      .pr-control-label {
        float: $ldirection;
        @include breakpoint($portrait-up) {
          float: $ldirection !important;
        }
        span {
          float: $ldirection !important;
        }
        .pr-required-indicator {
          float: $ldirection;
        }
      }
      .pr-helper-text {
        float: $ldirection;
        clear: both;
      }
      .btn-group-2-count {
        .pr-btn {
          text-align: $ldirection;
        }
      }
      .tag-container {
        .pr-cb-tag-container {
          float: $ldirection;
          width: 100%;
          @include breakpoint($medium-up) {
            width: 49%;
          }
        }
        .pr-cb-tag-container:nth-child(odd) {
          margin-#{$ldirection}: 0;
        }
        .pr-label-control {
          &:before {
            float: $ldirection;
          }
          span {
            text-align: $ldirection;
          }
        }
        .input-add-tag-field {
          float: $ldirection;
        }
        .pr-btn-add-tag {
          float: $ldirection;
          clear: both;
        }
        .pr-btn-add-tag-submit {
          border-#{$ldirection}: 0;
          border-#{$rdirection}: 1px solid $color-light-gray;
        }
      }
    }
    .pr-submit {
      .pr-subscript {
        text-align: $ldirection;
      }
      button {
        float: $ldirection !important;
      }
    }
  }
}

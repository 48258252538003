html[lang='en-ZA'],
html[lang='he-IL'] {
  #ot-sdk-btn {
    &.ot-sdk-show-settings {
      background-size: 15px !important;
      padding: 0 30px !important;
      border: 1px solid $color-light-gray !important;
      height: 30px !important;
      color: $color-gray-fb !important;
      font-weight: bold;
      cursor: pointer !important;
      margin-top: 5px !important;
      text-transform: capitalize;
      text-decoration: none;
    }
  }
  #onetrust-close-btn-container {
    .onetrust-close-btn-handler {
      bottom: 22px !important;
      height: 12px;
    }
  }
}

.site-header {
  .site-utils__inner {
    .site-utils__links {
      .site-utils__menu {
        .site-utils__link {
          letter-spacing: 1px;
        }
      }
    }
  }
  .site-utils__promo {
    .basic-carousel-formatter {
      .slick-dots {
        display: none !important;
      }
      .carousel-controls {
        .slick-prev {
          #{$rdirection}: 0;
          #{$ldirection}: auto;
        }
        .slick-next {
          #{$ldirection}: 0;
          &:before {
            #{$ldirection}: 0;
          }
        }
      }
    }
  }
  .site-header__menu {
    @include breakpoint($small-down) {
      transform: translateX(100%);
    }
  }
  ul {
    &.site-utils__menu {
      margin: 0 auto;
      .site-utils__item--cart {
        .cart_overlay {
          .cart-confirm-wrapper {
            #{$rdirection}: auto;
            #{$ldirection}: 0;
          }
        }
      }
    }
  }
}

.page-header__inner {
  padding: 0px 20px;
}

.touch {
  .shade-picker__color-name {
    select {
      height: 41px;
    }
  }
}

.checkout {
  .checkout__sidebar {
    #offer-code-panel {
      margin-bottom: 10px;
      .offer-code-panel__content {
        margin-top: 0px;
        #offer_code .offer_code_box {
          em,
          span {
            display: block;
            margin-bottom: 10px;
          }
        }
      }
    }
    #links-panel {
      padding: 16px 20px;
      border: 4px solid #eff0f0;
      ul.links_list li {
        border: 0px;
      }
    }
  }
}

.samples-page {
  .samples-panel__title {
    line-height: 25px;
  }
  .samples-buttons.bottom {
    display: block;
  }
}

#signin {
  .main .sign-in-page {
    .return-user {
      #signin input {
        width: 100%;
        margin-top: 10px;
      }
    }
    .new-account__item,
    .return-user__item {
      margin-bottom: 20px;
    }
    .new-account #registration_short input {
      width: 100%;
      margin-top: 10px;
    }
  }
}

.section-store-locator {
  .responsive-tabs--store-locator {
    .resp-tab-item:first-child {
      width: 60%;
      letter-spacing: 0.08em;
    }
    .resp-tab-item:last-child {
      display: none;
    }
  }
}

@media only screen and (orientation: portrait) {
  .section-customer-service-contact-us {
    .customer-service-section {
      .customer-service-contact-form__category {
        padding: 1em 9px;
        letter-spacing: 0px;
        word-break: break-word;
      }
    }
  }
}

@media only screen and (orientation: landscape) {
  .section-customer-service-contact-us {
    .customer-service-section {
      li#category-products {
        padding: 25px 2px;
      }
      .customer-service-contact-form__category {
        padding: 1em 40px;
        letter-spacing: 2.9px;
      }
      li#category-service {
        padding: 1em 12px;
      }
    }
  }
}

.device-mobile {
  .site-utils__links {
    .site-utils__link-text {
      letter-spacing: 0.2px;
    }
  }
  .site-email-sms-signup {
    &__header {
      padding: 40px 15px;
      h2 {
        font-size: 20px;
      }
    }
  }
  .site-email-sms-thank-you {
    &__header {
      padding: 40px 0 20px;
      h1 {
        margin-bottom: 40px;
      }
      h2 {
        margin: 10px 30px;
      }
    }
  }
}

@media only screen and (orientation: portrait) {
  .section-esearch {
    .product-grid {
      .product-thumb__abstract {
        .mobile-text {
          letter-spacing: 0em;
        }
        .product__button--add-to-bag {
          letter-spacing: 0em;
        }
      }
    }
    #search-results  {
      .pagination-container {
        .pagination {
          .paging-select {
            select {
              padding-#{$ldirection}: 25px;
            }
          }
        }
      }
      #product-results {
        float: none;
        border: none;
      }
      .results-header {
        display: none;
      }
      .product-grid__content {
        .product-brief__shades {
          .product-brief-shades__grid {
            .slick-prev {
              #{$rdirection}: auto;
              #{$ldirection}: 0;
            }
            .shade-inventory-status {
              &--4:after,
              &--7:after {
                border-#{$rdirection}: none;
                top: 10px;
              }
            }
          }
        }
      }
    }
  }
}

.section-customer-service-orders {
  .customer-service-section {
    .customer-service-landing-block__link {
      position: static;
    }
  }
}

.touch .shade-picker__color-name select {
  width: 100%;
  padding: 10px 30px 10px 0px;
  word-wrap: break-word;
  white-space: normal;
  height: auto;
  display: block;
  line-height: 20px;
}

.product-thumb {
  .product__button--add-to-bag {
    height: auto;
  }
  .product-thumb__cta {
    height: auto;
    line-height: 20px;
    padding: 5px 0px 5px 0px;
  }
}

.quickshop-wrapper.active:before {
  top: 1px;
}

.site-utils__nav {
  @include box-shadow(inset 7px 0 10px -7px #666);
  .menu__link--lvl-2,
  .menu__link--lvl-3 {
    height: auto;
  }
}

.site-header {
  .site-utils__links {
    .site-utils__menu {
      .site-utils__item--search {
        float: $rdirection;
      }
      .site-utils__item--stores {
        float: $ldirection;
      }
    }
  }
}

.product-full {
  &__media {
    .product-full-image__main__content {
      .slick-next {
        #{$ldirection}: auto;
        #{$rdirection}: -6px;
        &:before {
          #{$ldirection}: 10px;
        }
      }
      .slick-prev {
        #{$ldirection}: 10px;
        transform: rotate(0deg);
        &:before {
          #{$ldirection}: 0;
        }
      }
    }
  }
}

.customer-service-menu a.menu__link--lvl-1 {
  height: auto;
}

.makeup-lesson-page__slide-type {
  word-wrap: break-word;
  white-space: inherit;
}

@media only screen and (min-width: 320px) and (orientation: portrait) {
  .offers-details {
    .offers-details-item__button {
      word-wrap: break-word;
    }
  }
}

@media only screen and (min-width: 375px) and (orientation: portrait) {
  .offers-details {
    .offers-details-item__button {
      margin-top: 0px;
    }
  }
}

@media only screen and (min-width: 320px) and (orientation: portrait) {
  .offers-hero__container {
    .offers-hero__description .offers-hero__actions {
      a {
        width: 100%;
        text-align: center;
      }
    }
  }
  .offers-details {
    .offers-details-item {
      .offers-details-item__actions {
        .offers-details-item__link-container,
        .offers-details-item__button-container {
          width: 100%;
          a {
            width: 100%;
            text-align: center;
          }
        }
      }
    }
  }
}

.spp-product-layout__content-item-content {
  .spp-cross-sells__products {
    .product-brief__shades {
      .shade-inventory-status--4,
      .shade-inventory-status--7 {
        &:after {
          transform: rotate(-45deg);
        }
      }
    }
  }
}

.site-footer-contact__item--email {
  width: 100%;
  padding: 20px;
  a {
    border-#{$rdirection}: none !important;
    @include swap_direction(padding, 0px !important);
  }
  span.icon-email {
    display: inline-block;
    margin-bottom: 0px;
  }
}

.secure_message {
  font-family: Brandon Text Regular;
  p {
    text-align: center;
  }
  .secure_popup.submit {
    @include swap_direction(padding, 11px 0 11px 0px);
  }
}

.skin__foundation__dropdown__container {
  .skin__foundation__dropdown__button {
    height: 66px;
  }
  .skin__foundation__dropdown__select__container {
    height: 62px;
  }
  select.skin__foundation__dropdown__select {
    position: relative !important;
    margin-#{$ldirection}: 67px !important;
  }
  .skin__foundation__dropdown__select__copy {
    width: 94%;
    text-align: center;
    padding-#{$ldirection}: 23px;
  }
}

.skin__foundation__menu.skin__foundation__menu--03 {
  display: none;
}

.skin__foundation__menu.skin__foundation__menu--02 {
  display: none;
}

.touch {
  select {
    background-position: left 10px center;
  }
}

.product-brief__shades {
  button {
    &.slick-next {
      #{$ldirection}: auto;
      &:before {
        #{$ldirection}: 5px;
      }
    }
  }
}

.product-full-image__main__content {
  button {
    &.slick-next {
      #{$rdirection}: 0;
      #{$ldirection}: auto;
      &:before {
        #{$ldirection}: -10px;
      }
    }
    &.slick-prev {
      transform: none;
    }
  }
}

.spp-cross-sells {
  .spp-cross-sells__products {
    .product-grid--carousel {
      .product-grid__content {
        & > .slick-next {
          #{$rdirection}: 0;
          #{$ldirection}: auto;
          &:before {
            #{$ldirection}: 10px;
            transform: rotate(180deg);
            height: auto;
          }
        }
        & > .slick-prev {
          &:before {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}

.mpp-grid {
  &__item {
    .product-brief {
      &__shades {
        .shade-inventory-status--4,
        .shade-inventory-status--7 {
          &:after {
            transform: rotate(-45deg);
          }
        }
      }
    }
  }
}
.product-foundation-vto {
  &__overlay-btn img {
    margin-#{$rdirection}: 10px;
  }
  &__undertone-close,
  &__overlay-close {
    #{$ldirection}: 80%;
  }
  &__undertone-copy {
    padding: 10px 2px 0px 15px;
  }
}
[dir='rtl'] {
  .product-foundation-vto {
    &__btn {
      @include swap_direction(padding, 0 20px 20px 5px);
    }
  }
}

.product-vto {
  &__btn {
    margin-#{$ldirection}: 35px;
  }
}

.foundation-finder-result {
  &__sections {
    .slick-slider {
      .slick-prev {
        transform: rotate(180deg);
        #{$ldirection}: 0;
        #{$rdirection}: auto;
        &::before {
          #{$rdirection}: auto;
        }
      }
      .slick-next {
        transform: rotate(180deg);
        #{$rdirection}: 0;
        #{$ldirection}: auto;
        &::before {
          #{$ldirection}: auto;
        }
      }
    }
  }
}

.section-products {
  .site-content {
    overflow-x: hidden;
  }
}

.spp-product-layout {
  .basic-carousel-formatter {
    .slick-prev {
      right: 95%;
      position: absolute;
    }
    .basic-carousel {
      margin-bottom: 0;
    }
  }
}

.hot-collection {
  .product_collection_hero__content-item {
    .button--disabled {
      background-color: #ccc;
      border: 1px solid #ccc;
    }
    .js-quickshop-container {
      .quickshop {
        .product {
          .product__details {
            .product__actions {
              padding: 5px 0 0 10px;
              .product__button {
                margin-top: 5px;
              }
            }
          }
        }
      }
    }
    .block-template-product-collection-prodcut-p-v1 {
      .content {
        .product-palette-atb {
          .product__button--add-to-bag {
            width: 80%;
          }
        }
      }
    }
  }
}

.customer-service-quick-info__list-item {
  @include swap_direction(padding, 1.25em 0 !important);
}

.site-footer-contact {
  .icon-email:before {
    position: inherit !important;
  }
}

.customer-service-menu {
  a.menu__link--lvl-1 {
    width: auto;
  }
}

.sticky-add-to-bag {
  .sticky-add-to-bag__inner {
    @include breakpoint($tablet-down) {
      padding-bottom: 40px;
    }
  }
}

.site-footer {
  .site-footer-contact {
    ul {
      li.site-footer-contact__item--phone {
        float: none;
        width: 100%;
        text-align: center;
      }
    }
  }
  .sticky-chat {
    bottom: -6px !important;
  }
}

@media only screen and (max-device-width: 1024px) and (min-device-width: 768px) and (orientation: portrait) {
  .section-customer-service {
    .customer-service-section {
      .customer-service-landing-block {
        height: auto;
        width: 100%;
      }
    }
  }
}

@media only screen and (max-device-width: 1024px) and (min-device-width: 320px) and (orientation: portrait) {
  .store-locator--desktop {
    .store-locator__hero {
      fieldset#store-search-controls {
        input.search[type='text'],
        select.country {
          margin-bottom: 10px;
        }
      }
    }
  }
}

.appt-book-overlay {
  .overlay-content-container {
    .overlay-content h4 {
      font-size: 19px;
      margin-top: 1px;
    }
  }
}

.appt-book {
  select {
    background-position: 10px 50% !important;
  }
  .appt-book-location-selector {
    .location-submit {
      font-size: 10px !important;
    }
  }
  .service-select {
    .service {
      .service-head {
        height: 45px !important;
        .service-title {
          margin-top: 3px;
        }
      }
    }
  }
  .appointment-select {
    .appointments-container {
      .book-appt-container {
        .appt-book-mobile {
          font-size: 14px;
        }
        .registration__email-list {
          height: auto;
        }
        .appointment_privacy_legal_text {
          font-size: 12px;
        }
      }
    }
  }
  .product-thumb--price {
    height: auto !important;
    line-height: 20px !important;
  }
  .site-nav {
    .menu__link--lvl-2 {
      height: auto !important;
    }
  }
  .customer-service-section {
    div.active-subsection {
      ul {
        li {
          font-size: 11px;
        }
      }
    }
  }
  .homepage-hero__headline,
  .homepage-hero__headline span {
    font-size: 14px !important;
  }
  #confirmation {
    .confirmation-container {
      .appt-date-cal {
        background-position: 16.5em !important;
      }
    }
  }
}

@media only screen and (max-device-width: 1024px) and (min-device-width: 320px) and (orientation: portrait) {
  .homepage-hero__abstract--center {
    .homepage-hero__abstract__inner {
      .homepage-hero__headline {
        span,
        span.homepage-hero__link link,
        div {
          font-size: 20px !important;
        }
      }
    }
  }
  .homepage-hero__abstract--center {
    .homepage-hero__abstract__inner {
      p,
      p.homepage-hero__description {
        font-size: 12px !important;
      }
    }
  }
}

.site-utils__menu {
  .cart_overlay {
    top: 14px;
    opacity: initial;
    .cart-confirm-wrapper {
      border: solid 1px $color-gray;
      .checkout {
        .button {
          &.quickshop__button {
            float: left;
          }
        }
      }
    }
  }
}

.checkout {
  .address-form__item.city_container {
    input {
      width: 100%;
    }
  }
}

.slick-slider {
  .slick-prev {
    transform: rotate(180deg);
  }
  .slick-next {
    transform: rotate(180deg);
  }
}

.favorites-page {
  .slick-next {
    #{$rdirection}: 9999px;
  }
}

.spp-mobile-page {
  .product-gallery {
    .slick-prev {
      #{$ldirection}: -55px;
    }
  }
}

body.js-nav-expanded {
  overflow: visible;
}

.popup-offer-cbox {
  #cboxContent {
    .popup-offer__submit-wrap {
      .popup-offer__email-input {
        text-transform: lowercase;
      }
      h3.optional-info__header {
        border-top: 1px solid $color-black;
        border-bottom: none;
        margin: 15px 0 10px 0;
        padding: 16px 0 0px 0;
      }
      .sign-in-component__birthday-program {
        .day-of-birth__collection-field {
          select {
            background-position: 4%;
          }
        }
      }
    }
  }
}

.product--full {
  .product-gallery {
    &__main {
      @include swap_direction(padding, 0 10px !important);
    }
    button {
      &.slick-next {
        &:before {
          transform: rotate(0deg);
        }
      }
      &.slick-prev {
        #{$ldirection}: -25px;
        &:before {
          transform: rotate(0deg);
        }
      }
    }
  }
}

#lpChat {
  .lp_radiobutton_wrapper {
    .lp_radio_button {
      input[type='radio'] {
        display: none;
      }
    }
  }
}

.site-utils {
  .site-utils__nav {
    span {
      &.menu__link.menu__link--lvl-1 {
        &:after {
          transform: rotate(90deg);
        }
      }
    }
  }
}

#INDbtnWrap {
  #INDmenu-btn {
    svg {
      width: 100px;
    }
  }
}

.customer-service.customer-service-landing {
  .customer-service-section {
    #artist_chat {
      display: block;
      a.customer-service-landing-block__link {
        bottom: 0px;
      }
    }
  }
}

.site-footer {
  .site-footer-contact {
    li {
      &.site-footer-contact__item {
        &--phone,
        &--email,
        &--chat {
          .site-footer-contact__link {
            .icon-phone {
              &:before {
                right: 46px;
              }
            }
            span {
              &:last-child {
                padding: 0 15px;
              }
            }
            .icon-livechat {
              padding: 0 6px !important;
              &:before {
                right: 8px !important;
              }
            }
          }
        }
        &--phone {
          float: right !important;
        }
      }
    }
  }
}

.product__title {
  .product_brief__header,
  .at-h2 {
    font-size: 18px;
  }
}

.mpp-sort-menu {
  &.selectBox {
    background: $color-white;
  }
}

.bobbi-v2 {
  body {
    overflow-x: hidden;
  }
}

$disabled-color: #cccccc;
$disabled-cursor: text;

@mixin disable-button() {
  background-color: $disabled-color;
  cursor: $disabled-cursor;
}

.mobile-breadcrumb {
  @include swap_direction(margin, 10px 0px 0 0px);
  display: block;
}

.checkout {
  position: static;
  ol.checkout-progress {
    li {
      font-size: 12px;
      display: list-item;
      float: $ldirection;
      line-height: 1.4em;
      list-style-position: inside;
      list-style-type: decimal;
      @include swap_direction(margin, 0);
      @include swap_direction(padding, 3px 0);
      width: 50%;
    }
  }
}

#shipping .checkout-progress__shipping,
#review .checkout-progress__review,
#billing .checkout-progress__billing,
#confirm .checkout-progress__confirm {
  font-weight: bold;
}

#address_form_container {
  border: 0px;
}

#payment {
  .checkout {
    .payment_method_container {
      fieldset {
        @include swap_direction(padding, 17px);
        display: block;
        &.credit_card {
          border-top: 1px solid $color-black;
        }
        &.paypal {
          border-bottom: 1px solid $color-black;
        }
        img {
          float: $rdirection;
        }
      }
    }
  }
  &.opc__payment {
    .billing {
      .payment_method_container {
        @include swap_direction(padding, 15px 0px 15px);
        min-height: 200px;
        .group2 {
          @include swap_direction(padding, 16px 15px 16px 15px);
          border-top: 1px solid $color-black;
          border-bottom: 1px solid $color-black;
          display: block;
          font-family: $brandon-text-bold;
          font-size: 16px;
          letter-spacing: 0.2em;
          text-transform: uppercase;
          line-height: 22px;
          &.paypal {
            border-top: none;
            img {
              height: 23px;
              width: 38px;
            }
          }
          input[type='radio'] {
            ~ label {
              &:before {
                margin-top: 3px;
                float: $ldirection;
              }
            }
          }
        }
        .legal_txt {
          padding-top: 25px;
        }
      }
    }
  }
}

.shipping {
  .checkout__content {
    .shipping-page-info-panel {
      .shipping-address-info {
        .address_controls {
          margin-bottom: 15px;
        }
        #choose-address {
          select {
            margin-top: 10px;
          }
        }
      }
    }
  }
  p {
    margin-bottom: 15px;
  }
}

.checkout {
  .column.left {
    .checkout-buttons-container {
      .checkout-buttons.disabled {
        background-color: #cccccc;
      }
      &.trans_detail_item {
        display: block;
        position: fixed;
        z-index: 999;
        bottom: 0;
        #{$ldirection}: 0;
        width: 100%;
        background: $color-gray;
        height: auto;
        border-top: 1px solid $color-black;
        border-bottom: 1px solid $color-black;
        input,
        a {
          display: block;
          @include swap_direction(margin, 25px auto 0 auto);
          width: 275px;
          background-color: $color-pink;
        }
      }
    }
  }
}

#shipping,
#viewcart,
#samples,
#review,
#billing,
#confirm,
#payment {
  #footer {
    .sticky-offer,
    .sticky-chat {
      display: none !important;
      visibility: hidden;
    }
  }
}

.order-details {
  .order-products .cart-item__total {
    float: $rdirection;
    margin-top: 10px;
  }
}

.payment-info-panel {
  .payment-type-panel span {
    label {
      display: block;
      overflow: hidden;
      height: 60px;
      @include swap_direction(padding, 20px 0px);
      img {
        position: absolute;
        margin-top: -7px;
        #{$ldirection}: 30px;
      }
      &#form--checkout_payment_type--field--PAYMENT_OPTION--index--payment_option_visa_label {
        img {
          width: 60px;
        }
      }
      &#form--checkout_payment_type--field--PAYMENT_OPTION--index--payment_option_debit_label {
        img {
          margin-top: -20px;
        }
      }
      &#form--checkout_payment_type--field--PAYMENT_OPTION--index--payment_option_mc_label {
        img {
          margin-top: -20px;
        }
      }
    }
  }
}

.checkout .checkout__content {
  #viewcart-panel {
    .viewcart-buttons-panel .continue-checkout.disabled {
      @include disable-button();
    }
  }
  #bottom-viewcart-buttons .viewcart-buttons-panel {
    .add-button .disabled.continue-checkout {
      @include disable-button();
    }
  }
  #checkout_review .checkout-buttons-container {
    #continue-checkout-btm a.disabled {
      @include disable-button();
    }
  }
}

#colorbox {
  .waitlist-iframe-wrapper {
    width: 100%;
    height: 95%;
  }
}

.shipping-panel__title {
  text-indent: 0px;
  line-height: inherit;
  @include swap_direction(padding, 15px);
}

.confirm {
  #print-order {
    margin-bottom: 20px;
    .print-buttons a {
      width: 100%;
    }
  }
  #sidebar {
    .return-link-container {
      margin-top: 15px;
    }
  }
}

.viewcart {
  .checkout__content {
    #viewcart-panel .viewcart-panel__content {
      margin-top: 15px;
      .empty-cart {
        h4.checkout__subtitle {
          padding-#{$ldirection}: 20px;
        }
      }
      .signin-to-see-cart {
        margin-#{$ldirection}: 20px;
      }
    }
  }
}

#samples-panel,
#checkout_samples {
  .samples {
    .deactivate {
      .sample-select-button,
      .sample-select-button:hover {
        opacity: 0.3;
        cursor: default;
        background-color: #000000;
        border-color: #d4d4d4;
      }
    }
  }
}

.samples-page {
  .samples-panel {
    .sample-select-button:hover {
      background: #000000;
      border-color: #000000;
    }
    .selected,
    .selected:hover {
      background: #ff4661;
      border-color: #ff4661;
    }
  }
}

@media only screen and (max-width: 380px) {
  .billing {
    #checkout-wrapper .payment_frame {
      iframe {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 320px) {
  .mobile-breadcrumb {
    ol.checkout-progress li {
      font-size: 10px;
    }
  }
}

#shipping,
#viewcart,
#samples,
#review,
#billing,
#confirm,
#payment {
  .site-footer {
    .sticky-offer,
    .sticky-chat {
      display: none !important;
      visibility: hidden;
    }
  }
}
/* Mobile Checkout Optimization */
.opc__cart,
.opc__signin,
.opc__samples,
.opc__shipping,
.opc__review,
.opc__payment {
  .site-footer {
    .footer-link-container {
      .footer-link {
        width: 100%;
        margin: 5px 0;
        height: auto;
      }
    }
    .wrap {
      &.site-footer__wrap {
        background: $color-gray-background;
        padding-bottom: 135px;
      }
    }
  }
  .checkout__sidebar {
    ul.links_list {
      li {
        &.link {
          display: block;
        }
      }
    }
    #links-panel {
      border: none;
    }
  }
}

.opc__shipping {
  #gift-options {
    border-top: 1px solid $color-black;
    border-bottom: none;
  }
  #accept_terms {
    border-bottom: none;
    display: none;
  }
  .sms_promo_container {
    padding-bottom: 15px;
  }
  .custom-outline {
    outline: 0;
  }
  .cart-items {
    .remove_link {
      left: 0px;
    }
  }
}

.viewcart {
  .empty {
    .recommended-products-panel {
      .slick-next {
        width: auto;
      }
      .slick-prev,
      .slick-next {
        top: 37%;
      }
    }
  }
}

.checkout-co-enabled {
  .checkout {
    .left {
      #viewcart-panel {
        .content {
          .cart-items {
            .cart-item {
              &__qty {
                .qty_select {
                  background-position: 10px;
                }
              }
              &__remove-form {
                .remove_link {
                  #{$rdirection}: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}
